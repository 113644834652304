<template>
  <div class="col-md-6 mb-3">
    <div class="card border-0 shadow-sm cursor-pointer">
      <div class="card-body">
        <div class="row">
          <div class="col-3 my-auto">
            <i :class="icon" class="fad fa-4x text-primary"></i>
          </div>
          <div class="col-9 my-auto">
            <h5 class="mb-0 text-primary">
              <slot></slot>
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["icon"]
};
</script>