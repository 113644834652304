<template>
    <div class="container settings">
        <div class="row mb-3">
            <navigation-tile icon="fa-user" @click.native="goTo('/settings/details')">
                Your Details
            </navigation-tile>
            <navigation-tile icon="fa-bell" @click.native="goTo('/settings/notifications')">
                Notification Settings
            </navigation-tile>
        </div>
    </div>
</template>

<script>
    import NavigationTile from "@/views/globals/NavigationTile";
    export default {
      components: {NavigationTile},
      props: ["user"],
        data() {
            return {};
        },
        computed: {
        },
        methods: {
            goTo(link) {
              this.$router.push(link);
            }
        }
    };
</script>

<style>
</style>
